var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.site)?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","fab":"","x-small":"","title":"Start a new scan","to":{ name: 'create-scan', params: { siteId: _vm.site.id } }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-spacer'),(_vm.onRefreshClicked)?_c('refresh-button',{attrs:{"loading":_vm.refreshing,"onClick":_vm.onRefreshClicked}}):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.refreshing ? [] : _vm.scans,"item-key":"id","sort-by":['timestamp'],"sort-desc":[true],"loading":_vm.refreshing,"loading-text":"Loading Scans..."},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-none d-md-block"},[_vm._v(_vm._s(_vm.moment(item.timestamp).format('MMMM D, YYYY - h:mm:ss a')))]),_c('span',{staticClass:"d-md-none"},[_vm._v(_vm._s(_vm.moment(item.timestamp).format('M/D/YY - h:mm a')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('scan-status',{attrs:{"status":item.status}})]}},{key:"item.pageCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scannedCount)+"/"+_vm._s(item.pageCount)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","to":{ name: 'scan-details', params: { id: item.id } }}},[_vm._v(" Details "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }