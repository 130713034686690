<template>
  <v-container v-if="site">
    <h1 class="mb-4 text-center">{{site.name}}</h1>

    <site-overview :site="site" />
    <scan-list
      title="Scan History"
      :scans="scans"
      :refreshing="refreshing"
      :onRefreshClicked="onRefreshScans.bind(this)"
      :site="site"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ScanList from '@/components/scans/ScanList.vue'
  import SiteOverview from '@/components/sites/SiteOverview.vue'

  export default {
    title() {
      return this.site?.name
    },

    components: {
      SiteOverview,
      ScanList
    },

    props: ['id'],

    data: () => ({
      refreshing: false
    }),

    computed: {
      ...mapGetters({
        site: 'sites/site',
        scans: 'scans/scanList'
      }),
    },

    created: function() {
      this.onRefreshScans()
    },

    beforeRouteEnter: async function(to, from, next) {
      await next(async vm => {
        vm.setLoading(true)
        await vm.getDetails(to.params.id)
        vm.setLoading(false)
      })
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        getDetails: 'sites/getSiteDetails',
        getScans: 'scans/getSiteScans'
      }),
      onRefreshScans: async function() {
        if (this.site) {
          this.refreshing = true
          await this.getScans(this.site.id)
          this.refreshing = false
        }
      }
    }
  }
</script>
