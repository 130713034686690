<template>
  <v-card class="pa-4 mb-6">
    <v-card-title>
      Site Overview
      <v-btn
        color="primary"
        fab
        x-small
        class="ml-3"
        title="Edit site details"
        :to="{ name: 'edit-site', params: { id: site.id } }"
      ><v-icon>mdi-playlist-edit</v-icon></v-btn>
      <v-dialog v-model="showDeleteDialog" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" fab x-small class="ml-3" title="Delete site" v-bind="attrs" v-on="on">
                      <v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-h5">
                        Delete site
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to delete this site?
                        <br><br>
                        <b>WARNING:</b> This action cannot be undone.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="showDeleteDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn color="error" :loading="deleting" @click="onDeleteSite">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-card-title>
    <v-card-text>
      <b>Base URL:</b> <a :href="site.baseUrl" target="_blank">{{ site.baseUrl }}</a>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  // import RefreshButton from '@/components/RefreshButton.vue'

  export default {
    props: [
      'site',
      // 'onRefreshClicked',
    ],
    
    data: () => ({
        showDeleteDialog: false,
        deleting: false
    }),

    // components: {
    //   RefreshButton
    // },

    methods: {
      ...mapActions({
        setSnackbar: 'setSnackbar',
        deleteSite: 'sites/deleteSite'
      }),
      onDeleteSite: async function () {
        await this.deleteSite(this.site.id);

        this.setSnackbar({
                    message: `Site deleted successfully!`
                })

        this.$router.push({ name: 'sites' })
  }
  }
}

  
</script>