<template>
  <v-card class="pa-4">
    <v-card-title>
      {{title}}
      <v-btn
        v-if="site"
        color="primary"
        fab
        x-small
        class="ml-3"
        title="Start a new scan"
        :to="{ name: 'create-scan', params: { siteId: site.id } }"
      ><v-icon>mdi-plus</v-icon></v-btn>
      <v-spacer />
      <refresh-button
        v-if="onRefreshClicked"
        :loading="refreshing"
        :onClick="onRefreshClicked"
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="refreshing ? [] : scans"
        item-key="id"
        :sort-by="['timestamp']"
        :sort-desc="[true]"
        :loading="refreshing"
        loading-text="Loading Scans..."
      >
        <template v-slot:[`item.timestamp`]="{ item }">
          <span class="d-none d-md-block">{{moment(item.timestamp).format('MMMM D, YYYY - h:mm:ss a')}}</span>
          <span class="d-md-none">{{moment(item.timestamp).format('M/D/YY - h:mm a')}}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <scan-status :status="item.status" />
        </template>
        <template v-slot:[`item.pageCount`]="{ item }">
          {{item.scannedCount}}/{{item.pageCount}}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              text
              color="primary"
              :to="{ name: 'scan-details', params: { id: item.id } }"
            >
              Details
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import ScanStatus from './ScanStatus.vue'
  import RefreshButton from '@/components/RefreshButton.vue'

  export default {
    components: {
      ScanStatus,
      RefreshButton
    },

    props: [
      'title',
      'scans',
      'refreshing',
      'onRefreshClicked',
      'site'
    ],

    data: () => ({
      headers: [{
        text: 'Scan date',
        value: 'timestamp'
      }, {
        text: 'Status',
        value: 'status'
      }, {
        text: 'Crawl depth',
        value: 'depth'
      }, {
        text: 'Scanned pages',
        value: 'pageCount'
      }, {
        text: '',
        value: 'actions',
        sortable: false
      }]
    }),

    methods: {
      pageCountText: function(scan) {
        if (scan.pageCount > 0) {
          return scan.pageCount
        } else {
          if (scan.status === 'Scheduled' || scan.status === 'Running') {
            return 'Pending'
          } else {
            return 'Unknown'
          }
        }
      }
    }
  }
</script>
